import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivateLayout from "../../Layouts/PrivateLayout";
import { getUserProfile } from "../../store/actions/authAction";
import Header from "./header";
import Sidebar from "./sidebar";
import Home from "./home";
import DailyStats from "./daily-stats";
import WeeklyPhotos from "./weekly-photos";
import Receipe from "./recipe";
import Posts from "./Posts";
import {
  getDailyNotes,
  getVideos,
  getWeights,
} from "../../store/actions/dashboardAction";
import TempPage from "./temp";
import { getLocalData } from "../../utils/functions";
import CoachDashboard from "./coach/coach-dashboard";
import Journey from "./journey";
import Testimonials from "./testimonials";
import GlobalState from "./coach/global-state";
import Referral from "./referral";
import Profile from "./profile";
import RestartJourney from "./RestartJourney";
import BonusVideos from "./bonus-videos";
import PhotoStamps from "./photo-stamps";

const Dashboard = ({ history }) => {
  const [view, setView] = useState("dashboard");
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const pathName = window.location.pathname;

  const { loginUser } = useSelector((state) => state.auth);

  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getUserProfile());
    if (userType !== "admin") {
      dispatch(getWeights());
    }
  }, []);

  useEffect(() => {
    if (loginUser?.contentDay && userType !== "admin") {
      dispatch(getDailyNotes(loginUser?.contentDay));
    }
  }, [loginUser]);

  useEffect(() => {
    setLoading(true);
    dispatch(getVideos()).then((res) => {
      setLoading(false);
    });
  }, []);

  return (
    <PrivateLayout history={history}>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Sidebar
            loginUser={loginUser}
            view={view}
            setView={setView}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            history={history}
            pathName={pathName}
          />

          <div
            className="layout-page"
            style={{ backgroundColor: "", padding: 0 }}>
            <Header
              history={history}
              loginUser={loginUser}
              setShowSidebar={setShowSidebar}
            />

            <div className="content-wrapper">
              {pathName === "/" && userType === "member" && (
                <Home
                  history={history}
                  loginUser={loginUser}
                />
              )}
              {pathName === "/dashboard" && userType === "member" && (
                <Home
                  loginUser={loginUser}
                  history={history}
                />
              )}

              {pathName === "/" && userType === "coach" && (
                <CoachDashboard loginUser={loginUser} />
              )}
              {pathName === "/dashboard" &&
                (userType === "admin" || userType === "coach") && (
                  <GlobalState loginUser={loginUser} />
                )}
              {(pathName === "/members" || pathName === "/coaches") &&
                (userType === "coach" || userType === "admin") && (
                  <CoachDashboard
                    loginUser={loginUser}
                    coaches={pathName === "/coaches"}
                    key={pathName}
                  />
                )}

              {pathName === "/daily-stats" && (
                <DailyStats loginUser={loginUser} />
              )}
              {pathName === "/weekly-photos" && (
                <WeeklyPhotos loginUser={loginUser}  history={history}/>
              )}
              {pathName === "/testimonials" && (
                <Testimonials
                  loginUser={loginUser}
                  history={history}
                />
              )}
              {pathName === "/recipes" && <Receipe />}
              {pathName === "/journey" && <Journey loginUser={loginUser} />}
              {pathName === "/kudos-board" && (
                <Posts
                  loginUser={loginUser}
                  history={history}
                />
              )}
              {pathName === "/referral" && <Referral loginUser={loginUser} />}
              {pathName === "/profile" && <Profile loginUser={loginUser} />}
              {pathName === "/bonusVideos" && (
                <BonusVideos
                  loading={loading}
                  loginUser={loginUser}
                />
              )}
              {pathName === "/restartjourney" && (
                <RestartJourney
                  loginUser={loginUser}
                  history={history}
                />
              )}
              {pathName === "/photo-stamps" && (
                <PhotoStamps
                  loginUser={loginUser}
                  history={history}
                />
              )}
            </div>
            {showSidebar && (
              <div
                className="layout-overlay-sidebar"
                onClick={() => setShowSidebar(false)}></div>
            )}
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Dashboard;
