import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../forms/TextField";
import { getLocalData, inputValidator } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/actions/authAction";
import PublicLayout from "../../Layouts/PublicLayout";
import { saveUserPlaneStartsOn } from "../../store/actions/dashboardAction";

const schema = yup.object({
  username: yup.string().trim().required("Username is Required"),
  // .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
});

const Login = ({ history }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [loginButtonClick, setLoginButtonClick] = useState(false);
  const { error } = useSelector((state) => state.ui);

  const token = getLocalData("token");
  const userId = getLocalData("userId");

  useEffect(() => {
    if (
      token &&
      userId &&
      !window.location?.search?.includes("?auth_refresh") &&
      !loginButtonClick
    ) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [token, loginButtonClick]);

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search.includes("?redirect_to=")
    ) {
      sessionStorage.setItem(
        "redirect_to",
        window.location.search.replace("?redirect_to=", ""),
      );
    } else {
      sessionStorage.removeItem("redirect_to");
    }
  }, []);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loginHandler = async (data) => {
    setIsLoading(true);
    setLoginButtonClick(true);
    const res = await dispatch(loginUser(data));
    setIsLoading(false);

    if (res?.token) {
      setTimeout(() => {
        dispatch(saveUserPlaneStartsOn({ lastLogin: new Date() }));
      }, 5000);
      if (window.location.href.includes("alulawellness.com")) {
        window.open(
          `https://alulawellness.com/btm2auth?mo_jwt_token=${res?.mo_jwt_token}`,
          "_self",
        );
      } else {
        if (res?.isClient || res.type == "coach" || res.type == "admin") {
          history.push("/dashboard");
        } else {
          history.push("/journey");
        }
      }
    }
  };

  return (
    <PublicLayout history={history}>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="app-brand justify-content-center mb-5 border-bottom pb-4">
              <img
                src="assets/img/logo.png"
                style={{ width: 260 }}
              />
            </div>

            <form
              className="mb-3"
              onSubmit={handleSubmit(loginHandler)}>
              <div className="mb-4">
                <TextField
                  text="@"
                  placeholder="Username"
                  fieldName="username"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="mb-4 form-password-toggle">
                <TextField
                  text={<i className="bx bxs-lock-alt"></i>}
                  placeholder="Password"
                  fieldName="password"
                  inputType={passwordType}
                  register={register}
                  errors={errors}
                  viewPassword={() =>
                    setPasswordType(
                      passwordType === "text" ? "password" : "text",
                    )
                  }
                />
              </div>
              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <div className="form-check">
                    {/* <input
                      className="form-check-input border-0 me-3"
                      type="checkbox"
                      id="remember-me"
                    />
                    <label className="form-check-label" for="remember-me">
                      {" "}
                      Remember Me{" "}
                    </label> */}
                  </div>
                  <a
                    href={`https://alulawellness.com/my-account/lost-password`}
                    target="_blank">
                    Forgot Password?
                  </a>
                </div>
              </div>
              <div className="mb-4">
                <button
                  disabled={isLoading}
                  style={{ height: 50 }}
                  className="btn btn-xl w-100 br-10"
                  type="submit">
                  {isLoading ? (
                    <div className="spinner-border"></div>
                  ) : (
                    "Log in"
                  )}
                </button>
              </div>
            </form>
            <p
              style={{
                color: "red",
                fontSize: "16px",
                marginTop: -14,
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}>
              {!isLoading && error}
            </p>

            <p className="text-center">
              <span>Don't have an account yet?</span>
              <a
                href={`https://alulawellness.com/breakthrough-m2/get-started`}
                target="_blank"
                className="semi primary-color">
                <span> Sign Up</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Login;
