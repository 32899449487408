import { GET_PROFILE, LOGIN, URL } from "../../apis/routes";
import ApiService from "../../services/ApiServices";
import { getLocalData, setLocalData } from "../../utils/functions";
import { SET_USER_DATA } from "../constants/authConstants";
import { setUIKey } from "./uiAction";

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${LOGIN}`, data);

    if (res?.data?.token) {
      if (sessionStorage.getItem("redirect_to")) {
        if (res?.data?.mo_jwt_token) {
          window.open(
            `${sessionStorage.getItem("redirect_to")}?mo_jwt_token=${
              res?.data?.mo_jwt_token
            }`,
            "_self",
          );
        } else {
          window.open(`${sessionStorage.getItem("redirect_to")}`, "_self");
        }
        sessionStorage.removeItem("redirect_to");

        return false;
      } else {
        setLocalData("token", res?.data?.token || "");
        setLocalData("mo_jwt_token", res?.data?.mo_jwt_token || "");
        setLocalData("userId", res?.data?.userId || "");
        setLocalData("userType", res?.data?.type || "");
        setLocalData("email", data.email || "");
        setLocalData("profilePic", res?.data.profilePic || "");
        if (res?.data?.verified) setLocalData("verified", "yes");
        dispatch(setUserToken());
        dispatch(setUIKey("error", ""));
      }
    } else {
      dispatch(setUIKey("error", res?.data?.error || "Error"));
    }
    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserProfile = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_PROFILE}`);
    if (res?.data) {
      dispatch(
        setUserData({
          loginUser: res.data,
        }),
      );
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.reload();
    return error;
  }
};

export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
});

export const setUserToken = () => async (dispatch) => {
  let token = getLocalData("token") || "";

  dispatch({
    type: SET_USER_DATA,
    payload: {
      token,
    },
  });
};
