import { getLocalData } from "../../utils/functions";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";

const initialState = {
  userOldStats: null,
  userStats: null,
  weeklyPhotos: null,
  dailyNotes: null,
  posts: null,
  testimonials: null,
  comments: [],
  coachMembers: [],
  receipes: [],
  journeyTitles: null,
  userWeights: null,
  totalRecipes: 0,
  receipeRatings: null,
  coachDetails: null,
  referralData: null,
  signupsData: null,
  notifications: null,
  categories: [],
  recipeTags: [],
  coachingLinks: [],
  isReadAll: 0,
  bonusVideosData: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
